import React from 'react';
import Button from 'react-bootstrap/Button';
import ContactIcons from './ContactIcons';

export default function Home() {
  return (
    <div className="home row" id="about">
      <div className="col-12 my-auto">
        <span className="home-name">Alex Alemzadeh</span>
        <span className="tagline">Full-Stack Web Developer</span>
        {/* <span className="bio">{"This is my bio. ".repeat(25)}</span> */}
        {/* <span className="bio">{"I'm a full-stack developer, passionate about building elegant, functional websites."}</span> */}
        <p></p>
        <Button href="#portfolio" variant="light" size="lg" className="my-4">Check out my portfolio</Button>
        <span className="home-contact-heading">Contact me</span>
        <ContactIcons />
      </div>
    </div>
  )
}