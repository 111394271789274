import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaLinkedin, FaGithubSquare, FaEnvelope, FaFileAlt } from 'react-icons/fa';

export default function ContactIcons() {
  return (
    <Row className="contact-icons contact-icons-separate">
      <Col /* xs={6} md={6} */>
        <a href="https://www.linkedin.com/in/alex-alemzadeh-7269b754/" title="LinkedIn" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={60} />
        </a>
      </Col>
      <Col /* xs={6} md={6} */>
        <a href="https://github.com/alexalemz" title="GitHub" target="_blank" rel="noopener noreferrer">
          <FaGithubSquare size={60} />
        </a>
      </Col>
      {/* <Col xs={6} md={3}>
        <FaEnvelope size={100} title="Email me" />
      </Col>
      <Col xs={6} md={3}>
        <FaFileAlt size={100} title="My resume" />
      </Col> */}
    </Row>
  )
}