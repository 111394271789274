import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="container-fluid">
        <Home />
        <Portfolio />
        {/* <Contact /> */}
      </div>
    </div>
  );
}

export default App;
