import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaLinkedin, FaGithubSquare, FaGithub, FaGlobeAmericas } from 'react-icons/fa';

export default function Portfolio() {
  const projects = [
    {
      title: "Social App",
      img: "social-app.jpg",
      tech: "React, MySQL, Express, Passport",
      htmlDescription: `
        <p>A micro-blogging app, similar to <strong>Twitter</strong>, where users can make posts, comment on posts, and follow other users. </p>
        <p>On the home page is a feed of all posts from the people they follow.</p>
        <p style='display:none'>The posts of the people they follow will show up on their home feed.</p>
      `,
      description: "",
      deployed: "https://shielded-anchorage-31764.herokuapp.com/",
      github: "https://github.com/alexalemz/socialapp",
    },
    {
      title: "Snake Game",
      img: "snake.jpg",
      tech: "JavaScript, HTML, Firebase",
      htmlDescription: `
        <p>The game Snake, built with JavaScript, using HTML and CSS for the graphics. Optionally, users can sign in to record their scores. Firebase is used for user authentication and storing user scores.</p>
        <p>Keyboard arrow keys are used to control the snake's movement. On mobile devices, arrow buttons appear onscreen, which the player can press.</p>`,
      description: "A recreation of the game Snake, built using using HTML and CSS for the graphics, JavaScript for gameplay functionality, and Firebase for user authentication and storing user scores. Keyboard arrows are used to control the snake's movement. The site is mobile responsive; on mobile devices, arrow buttons appear, which the player can use instead of keyboard keys.",
      deployed: "https://snake-1f5f5.firebaseapp.com/",
      github: "https://github.com/alexalemz/snake",
    },
    {
      title: "NYT Article Search",
      img: "nyt-react.jpg",
      tech: "React, MongoDB, Axios",
      description: "Written in React, this app uses the New York Times API to let users search for articles. Users can save these articles to read later. Links to the articles are stored in a MongoDB database.",
      deployed: "https://aqueous-lake-59860.herokuapp.com/",
      github: "https://github.com/alexalemz/nytreact",
    },
    {
      title: "Rock Paper Scissors",
      img: "rock-paper-scissors.jpg",
      tech: "Firebase, JavaScript, HTML",
      description: "An online two-player game of Rock Paper Scissors. Firebase is used to track the online users, their inputs, and their total scores.",
      deployed: "https://alexalemz.github.io/rps-multiplayer/",
      github: "https://github.com/alexalemz/rps-multiplayer",
    },
    {
      title: "Clicky Game",
      img: "clicky-game.jpg",
      tech: "React",
      htmlDescription: `
        <p>A memory game built using React. The point of the game is to click each of the cards only once per game.</p>
        <p>If a card is clicked on a second time, the player loses and the game resets. Once all of the cards have been clicked on, the user wins and the game is over.</p>`,
      description: `A memory game built using React. The point of the game is to click each of the cards only once per game. If a card is clicked on a second time, the player loses and the game resets. Once all of the cards have been clicked on, the user wins and the game is over.`,
      deployed: "https://ancient-woodland-10467.herokuapp.com/",
      github: "https://github.com/alexalemz/clicky-game",
    },
    {
      title: "Expense Tracker",
      img: "expense-tracker.jpg",
      tech: "Node, Express, Passport, MySQL",
      description: `An app for users to track their spending. They can log each purchase they make, then they can view a record of all expenses, and even filter by category, description, or date range.`,
      deployed: "https://sheltered-headland-53159.herokuapp.com/",
      github: "https://github.com/alexalemz/expense-tracker",
    },
  ]

  // Wake up the Heroku sites
  useEffect(() => {
    projects.forEach(p => {
      let site = p.deployed;
      if (site.includes('heroku')) {
        fetch(p.deployed).then(r => console.log('Fetched', p.title))
      }
    })
  })

  // selected is set to the index of the selected project, or -1 if none are selected
  const [selected, setSelected] = useState(-1);

  const handleSelect = (idx) => {
    // Set selected to the appropriate index.
    idx === selected ? setSelected(-1) : setSelected(idx);
    // Show the Modal
    handleShow();
  }

  // For the Modal
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); setSelected(-1); }
  const handleShow = () => setShow(true);

  return (
    <div className="portfolio row" id="portfolio">
      <div className="col-12 my-auto">
        <span className="portfolio-text">Portfolio</span>
        <Container className="text-center" fluid>
          <Row>
            {projects.map((project, idx) => {
              let isSelected = selected === idx ? "project-selected" : "";
              return (
                <Col key={`project-${idx}`} xs md={6} lg={4} xl={4} >
                  <div className={`project ${isSelected}`}>
                    <span className="project-title">{project.title}</span>
                    <Image 
                      src={`/images/portfolio/thumb/${project.img}`} className="project-img" rounded 
                      onClick={e => handleSelect(idx)}
                    />
                    <span className="project-tech">{project.tech}</span>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>

        {/* Modal for project descriptions */}
        {
          (selected !== -1) && (
            <Modal show={show} onHide={handleClose} centered className="dark project-modal">
              <Modal.Header closeButton>
                <Modal.Title>{projects[selected].title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <p>(The screenshot)</p> */}
                {(projects[selected].htmlDescription) ? 
                  <div dangerouslySetInnerHTML={{ __html: projects[selected].htmlDescription }}></div>
                  : <p>{projects[selected].description}</p>}
                {/* <p>(Links to deployed site and github code.)</p> */}
                <Row className="text-sm-center mt-4">
                  <Col xs={6} sm={5}>
                    <a href={projects[selected].deployed} target="_blank" rel="noopener noreferrer">
                      <FaGlobeAmericas size={32} /> Live site
                    </a>
                  </Col>
                  <Col xs={6} sm={7}>
                    <a href={projects[selected].github} target="_blank" rel="noopener noreferrer">
                      <FaGithub size={32} /> GitHub code
                    </a>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          )
        }
      </div>
    </div>
  )
}