import React from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

function Header() {
  return (
    <div className="header">
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top" id="navbar">
        <a className="navbar-brand" href="/">Alex Alemzadeh</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {/* <ul className="navbar-nav ml-auto"> */}
            <Scrollspy className="navbar-nav ml-auto" items={ ['about', 'portfolio'/* , 'contact' */] } currentClassName="active">
              <li className="nav-item">
                <a className="nav-link" href="#about">Contact</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#portfolio">Portfolio</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
              </li> */}
            </Scrollspy>
          {/* </ul> */}
        </div>
      </nav>
    </div>
  )
}

// export default withRouter(Header);
export default Header;